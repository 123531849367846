import styled from "styled-components"

import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ChartTooltip({
  date,
  label,
  value,
  unit,
  minMaxIncluded,
  low,
  high,
}: {
  date: string
  label: string
  value: string | number
  unit: string
  minMaxIncluded?: boolean
  low?: string
  high?: string
}) {
  // N.B: For some reason adding a naked `:` in the JSX here triggers a
  // weird/invalid Highcharts warning; wrapping it in JS solves it.
  return (
    <Box>
      <Header>{date}</Header>
      <Content color="tertiary">
        <Row>
          <span>
            <span>{label + ": "}</span>
            <span>
              <Value>{value} </Value>
            </span>
            <span>{unit}</span>
          </span>
        </Row>
        {minMaxIncluded && (
          <Row>
            <span>
              <DownArrow>↓</DownArrow> <Value>{low ? low + unit : "-"}</Value>
            </span>
            <span>
              <UpArrow>↑</UpArrow> <Value>{high ? high + unit : "-"}</Value>
            </span>
          </Row>
        )}
      </Content>
    </Box>
  )
}

export const Box = styled.div`
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.25rem;
  min-width: 170px;
  font-family: Figtree, sans-serif;
`

export const Header = styled(MText)`
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.875rem 1rem;
  background: ${mColors.primaryLight};
`

export const Content = styled(MText)`
  background: white;
  padding: 0.875rem 1rem;
  border-radius: 0 0 0.25rem 0.25rem;
`

export const Row = styled.div`
  display: flex;
  gap: ${spacing.M};
`

export const DownArrow = styled.span`
  color: ${mColors.systemGood};
`

export const UpArrow = styled.span`
  color: ${mColors.systemError};
`

export const Value = styled.span`
  font-weight: bold;
`
